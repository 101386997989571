<template>
  <div class="p-2">
    <el-row class="rounded-xl shadow-xl bg-white p-4 my-4" v-loading="loading">
      <line-chart :chart-data="lineChartData" />
    </el-row>

    <el-row :gutter="32">
      <!--      <el-col :xs="24" :sm="24" :lg="8">-->
      <!--        <div class="chart-wrapper rounded-xl shadow-xl bg-white p-2 my-4">-->
      <!--          <raddar-chart />-->
      <!--        </div>-->
      <!--      </el-col>-->
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper rounded-xl shadow-xl bg-white p-2 my-4">
          <pie-chart v-loading="ratioLoading" :chart-data="pieChartData" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper rounded-xl shadow-xl bg-white p-2 my-4">
          <bar-chart v-loading="timeLoading" :chart-data="barChartData"/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pieChart from "@/pages/panel/components/PieChart.vue";
import LineChart from "./components/LineChart";
import RaddarChart from "./components/RaddarChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";

export default {
  name: "DashboardAdmin",
  computed: {
    pieChart() {
      return pieChart;
    },
  },
  components: {
    LineChart,
    RaddarChart,
    PieChart,
    BarChart,
  },
  data() {
    return {
      loading: false,
      ratioLoading: false,
      timeLoading:false,
      lineChartData: {
        date: [],
        data: [],
        name: ["近7天预约量"],
      },
      pieChartData: {
        male:0,
        feMale:0,
        other:0,
      },
      barChartData:[],
    };
  },
  async mounted() {
    await this.getSevenDay(); // 获取近七天的预约数量
    await this.getSexRatio(); // 获取用户性别占比
    await this.getTimes();    // 预约时间段
  },
  methods: {
    /**
     *  @actions:  获取近七天的预约量
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/30 22:59
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getSevenDay() {
      this.loading = true;
      this.$cloud
        .get("dashboard/week", {})
        .then((res) => {
          this.loading = false;
          if (res) {
            this.lineChartData = res;
          }
          console.log("res", res);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     *  @actions:  获取用户性别占比
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/30 23:20
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSexRatio() {
      this.ratioLoading = true;
      this.$cloud
        .post("dashboard/sex", {})
        .then((res) => {
          if (res) {
            this.pieChartData = res;
          }
          this.ratioLoading = false;
        })
        .catch(() => {
          this.ratioLoading = false;
        });
    },
    
    /**
     *  @actions:  预约时间段
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/30 23:41
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getTimes(){
      this.timeLoading = true
      this.$cloud.post('dashboard/hour',{}).then((res)=>{
        if(res){
          this.barChartData = res
        }
        this.timeLoading = false;
      }).catch(()=>{
        this.timeLoading = false;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
